import { BASEPATH } from "../../../utils/constants/constants";

const REGISTRATION_API_URL = {
    ALL_MASTER: `${BASEPATH}/api/web/registration/masterService/getCountries`,
    VALIDATE_USER: `${BASEPATH}/api/web/registration/checkExistingUser`,
    BLOCKED_DOMAIN_LIST: `${BASEPATH}/api/web/registration/masterService/getBlockedDomains`,
    DEFAULT_COUNTRY_DOMAIN: `${BASEPATH}/-/registration/defaultcountrydomain`,
    SEND_OTP: `${BASEPATH}/api/web/registration/sendOTP`,
    VALIDATE_OTP: `${BASEPATH}/api/web/registration/verifyOTP`,
    COMPANY_NAMES: `${BASEPATH}/-/registration/companynames`,
    REGISTER_USER: `${BASEPATH}/api/web/registration/registerUser`,
    VALIDATE_UPGRADE_USER: `${BASEPATH}/api/web/registration/userProfile/upgrade`,
    FETCH_JOB_TITLES: `${BASEPATH}/api/web/registration/masterService/getJobTitles`,
    VERIFY_INVITE_TOKEN: `${BASEPATH}/api/web/registration/organization/user-org-invitation/invite-token/authenticate`,
};

export { REGISTRATION_API_URL };
