import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../common-ui-modules/service/redux-helper/api-action-creator";
import { axiosApi } from "../../../common-ui-modules/service/redux-helper/middleware/axios-api";
import { REGISTRATION_API_URL } from "./constants";
import {
    PROFILE_BUILDER_OPTIONS,
    REGISTRATION_ORIGIN,
} from "../../../modules/Registration/ui/common/constants";
import { api } from "../../../common-ui-modules";

const slice = createSlice({
    name: "registration",
    initialState: {
        validatedUser: null,
        blockedDomainList: null,
        defaultCountryDomain: null,
        countriesList: [],
        countriesValueList: [],
        sendOtp: null,
        ValidateOtp: null,
        ValidateOtpFailed: false,
        verificationCode: null,
        companyNames: null,
        registerUser: null,
        loading: false,
        jobTitleArr: "",
        formStep3form: {
            firstName: "",
            lastName: "",
            company: "",
            companyId: "",
            country: { countryName: "Select Country", countryCode: null },
            jobTitle: "",
            password: "",
            confirmPassword: "",
            acceptTerms: false,
            state: "",
        },
        step3ContinuationForm: {
            phone: "",
            address: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            broadcomcontact: "",
            countryCode: "",
        },
        autoUpgradeStatus: false,
        userCountryName: "",
        profileBuilderData: PROFILE_BUILDER_OPTIONS,
        portletPath: "profilebuilder",
        federatedUserCheck: false,
        userValidate: false,
        validateOtp: null,
        otpEntered: null,
        isInviteTokenValid: false,
        siteId: null,
        isUserUpgraded: false,
        tokenType: ""
    },
    reducers: {
        validateUserReceived: (registration, action) => {
            if (action.payload) {
                const userData = action.payload;
                const isFederatedUser = userData && userData.data && userData.data.federatedUser;
                registration.validatedUser = userData && userData.data;
                registration.userValidate = true;
                registration.federatedUserCheck = isFederatedUser;
            }
        },
        resetValidatedUser: (registration) => {
            registration.validatedUser = null;
        },
        resetFederatedUserCheck: (registration) => {
            registration.federatedUserCheck = false;
        },
        resetUserValidateStatus: (registration) => {
            registration.userValidate = false;
        },
        blockedDomainListReceived: (registration, action) => {
            registration.blockedDomainList = action.payload.data.blockedList;
        },
        defaultCountryDomainReceived: (registration, action) => {
            registration.defaultCountryDomain = action.payload;
        },
        sendOtpReceived: (registration, action) => {
            registration.sendOtp = action.payload;
        },
        validateOtpReceived: (registration, action) => {
            registration.validateOtp = action.payload.data;
            registration.verificationCode = action.payload.verificationCode;
        },
        otpValidationFailed: (registration, action) => {
            registration.ValidateOtpFailed = action.payload;
        },
        resetValidateOtpReceived: (registration, action) => {
            registration.validateOtp = action.payload;
        },
        allMasterVOReceived: (registration, action) => {
            if (action.payload) {
                const masterVO = action.payload.data;
                let countries = [];
                countries = masterVO.result.map((item: any) => {
                    return { label: item.countryName, value: item.countryId };
                });
                countries.unshift({ label: "Select Country", value: null });
                registration.countriesList = countries;
                //country values
                const countryList: any = [];
                countries.map((item: any) => {
                    !!item.value && countryList.push(item.value.toString());
                });
                registration.countriesValueList = countryList;
            }
        },
        companyNamesReceived: (registration, action) => {
            registration.companyNames = action.payload;
        },
        registerUserReceived: (registration, action) => {
            if (action.payload.companyId) {
                registration.registerUser = action.payload.companyId;
            } else {
                registration.registerUser = action.payload;
            }
        },
        jobTitleArrReceived: (registration, action) => {
            registration.jobTitleArr = action.payload;
        },
        portletPathForRegReceived: (profileBuilder, action) => {
            profileBuilder.portletPath = action.payload;
        },
        setIsAutoUpgrade: (registration, action) => {
            registration.autoUpgradeStatus = action.payload;
        },
        setUserInformation: (registration, action) => {
            if (action.payload) {
                registration.formStep3form = action.payload;
            }
        },
        setUserCountryName(registration, action) {
            registration.userCountryName = action.payload.country.countryCode;
        },
        setStep3ContinuationInitialValues(registration, action) {
            if (action.payload) {
                registration.step3ContinuationForm.phone = action.payload.phone
                    ? action.payload.phone
                    : "";
                registration.step3ContinuationForm.address = action.payload.addressLine1
                    ? action.payload.addressLine1
                    : "";
                registration.step3ContinuationForm.address2 = action.payload.addressLine2
                    ? action.payload.addressLine2
                    : "";
                registration.step3ContinuationForm.city = action.payload.city
                    ? action.payload.city
                    : "";
                registration.step3ContinuationForm.state = action.payload.state
                    ? action.payload.state
                    : "";
                registration.step3ContinuationForm.zipcode = action.payload.postalCode
                    ? action.payload.postalCode
                    : "";
                registration.step3ContinuationForm.broadcomcontact = action.payload.broadcomcontact
                    ? action.payload.broadcomcontact
                    : "";
                registration.step3ContinuationForm.countryCode = action.payload.countryCode
                    ? action.payload.countryCode
                    : "";
            }
        },
        resetSendOtp: (registration) => {
            registration.sendOtp = null;
        },
        setOtpInput: (registration, action) => {
            registration.otpEntered = action.payload;
        },
        resetOtpInput: (registration) => {
            registration.otpEntered = null;
        },
        setTokenDetails: (state, action) => {
            if (action?.payload?.success) {
                state.siteId = action.payload?.data?.siteId;
                state.tokenType = action.payload?.data?.tokenType
                state.isInviteTokenValid = true;
            }
        },
        setIsUserUpgraded: (state, action) => {
            if (action?.payload?.success) {
                state.isUserUpgraded = true;
            }
        },
    },
});

export const {
    validateUserReceived,
    blockedDomainListReceived,
    defaultCountryDomainReceived,
    sendOtpReceived,
    validateOtpReceived,
    resetValidateOtpReceived,
    allMasterVOReceived,
    companyNamesReceived,
    registerUserReceived,
    jobTitleArrReceived,
    portletPathForRegReceived,
    setIsAutoUpgrade,
    setUserInformation,
    setUserCountryName,
    setStep3ContinuationInitialValues,
    resetFederatedUserCheck,
    resetUserValidateStatus,
    resetSendOtp,
    otpValidationFailed,
    resetValidatedUser,
    setOtpInput,
    resetOtpInput,
    setTokenDetails,
    setIsUserUpgraded,
} = slice.actions;

//custom actions
export const loadValidateUser = (email: string, source: string) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: REGISTRATION_API_URL.VALIDATE_USER,
            method: "POST",
            data: { emailId: email, source: source },
            onSuccess: validateUserReceived.type,
        }),
    );
};
export const loadResetValidateUser = () => (dispatch: any) => {
    dispatch(validateUserReceived(null));
};
// const userValidated = createAction()

export const loadBlockedDomainList = () => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: REGISTRATION_API_URL.BLOCKED_DOMAIN_LIST,
            method: "GET",
            onSuccess: blockedDomainListReceived.type,
            skipErrorHandling: true,
        }),
    );
};

export const loadDefaultCountryDomain = () => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: REGISTRATION_API_URL.DEFAULT_COUNTRY_DOMAIN,
            onSuccess: defaultCountryDomainReceived.type,
        }),
    );
};

//TODO: Fix error, have onError callback
export const loadSendOtp = (email: string, captchaToken: string | null) => (dispatch: any) => {
    dispatch(loadResetValidateOtp());
    return dispatch(
        apiCallBegan({
            url: REGISTRATION_API_URL.SEND_OTP,
            method: "POST",
            data: {
                verificationCode: captchaToken,
                source: "selfReg",
                captchaToken: "",
            },
            onSuccess: sendOtpReceived.type,
            skipErrorHandling: true,
            onError: sendOtpReceived.type,
        }),
    );
};
export const loadValidateOtp =
    (verificationCode: string, captchaToken: string) => (dispatch: any) => {
        const body = {
            verificationCode: verificationCode,
            source: "selfReg",
            //captchaToken, //TODO
        };

        axiosApi({
            url: REGISTRATION_API_URL.VALIDATE_OTP,
            method: "POST",
            data: body,
            skipErrorHandling: true,
        })
            .then((response) => {
                dispatch(validateOtpReceived({ data: response.data, verificationCode }));
            })
            .catch((error) => {
                dispatch(otpValidationFailed(true));
            });
    };
export const loadResetValidateOtp = () => (dispatch: any) => {
    dispatch(resetValidateOtpReceived(null));
};
export const loadAllMaster = () => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            method: "GET",
            url: REGISTRATION_API_URL.ALL_MASTER,
            onSuccess: allMasterVOReceived.type,
            skipErrorHandling: true,
        }),
    );
};

export const loadRegisterUser = (fields: any) => (dispatch: any, getState: any) => {
    const otp = getState().registration.otpEntered;
    const postData = {
        ...fields,
        source: "PORTAL",
        origin: REGISTRATION_ORIGIN,
        verificationCode: otp,
    };
    return dispatch(
        apiCallBegan({
            url: REGISTRATION_API_URL.REGISTER_USER,
            onSuccess: registerUserReceived.type,
            method: "POST",
            data: postData,
        }),
    );
};
export const validateAutoUpgrade = (fields: any, type: any) => (dispatch: any) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return dispatch(
        apiCallBegan({
            url: REGISTRATION_API_URL.VALIDATE_UPGRADE_USER,
            onSuccess: registerUserReceived.type,
            method: "POST",
            headers,
            data: fields,
        }),
    );
};
export const getjobTitleArr = () => (dispatch: any) => {
    api({
        method: "GET",
        url: REGISTRATION_API_URL.FETCH_JOB_TITLES,
        skipErrorHandling: true,
    }).then((res) => {
        if (
            res.data.success &&
            res.data.data &&
            res.data.data.jobtitles &&
            res.data.data.jobtitles.length
        ) {
            const resData = res.data.data.jobtitles;
            const list = [];
            list.push({ label: "Select Job Title", value: "" });
            resData.map((item) => {
                list.push({ label: item, value: item });
            });
            dispatch(jobTitleArrReceived(list));
        }
    });
};
export const verifyInviteToken = (token: string, email: string) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: `${REGISTRATION_API_URL.VERIFY_INVITE_TOKEN}`,
            method: "GET",
            config: {
                headers: {
                    "INVITE-TOKEN": `${token}`,
                    "LOGGED-IN-USER-ID": email,
                },
            },
            onSuccess: setTokenDetails.type,
        })
    );
};
export const upgradeUser = (fields: any, cloudPortalUrl: string) => (dispatch: any) => {
    return axiosApi({
        url: REGISTRATION_API_URL.VALIDATE_UPGRADE_USER,
        method: "POST",
        data: fields,
    }).then((res) => {
        if (res.status === 200 && res.data?.success) {
            dispatch(setIsUserUpgraded(res.data))
            window.location.href = cloudPortalUrl;
        }
    }
    );
};

//selectors
export const getValidateUser = (state: any) => state.registration.validatedUser;
export const getBlockedDomainList = (state: any) => state.registration.blockedDomainList;
export const getDefaultCountryDomain = (state: any) => state.registration.defaultCountryDomain;
export const getSendOtp = (state: any) => state.registration.sendOtp;
export const getValidateOtp = (state: any) => state.registration.validateOtp;
export const getCountryList = (state: any) => state.registration.countriesList;
export const getCountryValueList = (state: any) => state.registration.countriesValueList;
export const getCompanyNames = (state: any) => state.registration.CompanyNames;
export const getRegisterUser = (state: any) => state.registration.registerUser;
export const getAutoUpgradeStatus = (state: any) => state.registration.autoUpgradeStatus;
export const getFederatedUserCheck = (state: any) => state.registration.federatedUserCheck;
export const getUserValidateStatus = (state: any) => state.registration.userValidate;
export const getInviteTokenStatus = (state: any) => state.registration.isInviteTokenValid;
export const getSiteId = (state: any) => state.registration.siteId;
export const getTokenType = (state: any) => state.registration.tokenType;
const rawJobTitlesList = (state: any) => state.registration.jobTitleArr.payload;
//create selector memioze the selection
export const jobTitlesList = (state: any) => state.profileBuilder.jobTitleArr;
export const getOtpValidationStatus = (state: any) => state.registration.ValidateOtpFailed;
export const getProfilePath = (state: any) => {
    return state.profileBuilder.portletPath;
};
export const getUserCountryName = (state: any) => state.registration.userCountryName;
export const getStep3InitialValues = (state: any) => state.registration.formStep3form;
export const getStep3ContinuationInitialValues = (state: any) =>
    state.registration.step3ContinuationForm;
export const getOTP = (state: any) => state.registration.otpEntered;

// reducer
export const registrationReducer = slice.reducer;
